<template>
  <v-app>
    <default-bar />

    <default-drawer />

    <default-view />

    <default-footer />

    <!-- <default-settings /> -->
  </v-app>
</template>

<script>
  export default {
    name: 'DefaultLayout',
    data: () => ({
    items: [
      {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
    {
      title: 'Find Incident',
      icon: 'mdi-magnify',
      to: '/dashboard/findinc'
    },
    {
      title: 'Add Incident',
      icon: 'mdi-plus',
      to: '/addincident'
    },
    {
      title: 'Reports',
      icon: 'mdi-alert-circle-outline',
      to: '/dashboard/reports'
    },
    {
      title: 'Exports',
      icon: 'mdi-export',
      to: '/dashboard/exports'
    },
    {
      title: 'Statistics',
      icon: 'mdi-chart-bar',
      to: '/dashboard/stats'
    },
    {
      title: 'Full Search',
      icon: 'mdi-book-search-outline',
      to: '/dashboard/search'
    }
    // {
    //   title: 'Pages',
    //   icon: 'mdi-image',
    //   to: '/'
    // },
    // {
    //   title: 'Users',
    //   icon: 'mdi-account-multiple',
    //   to: '/dashboard/users'
    // }
    ]
  }),

    components: {
      DefaultBar: () => import(
        /* webpackChunkName: "default-app-bar" */
        '@/components/dashboard/AppBar'
      ),
      DefaultDrawer: () => import(
        /* webpackChunkName: "default-drawer" */
        '@/components/dashboard/Drawer'
      ),
      DefaultFooter: () => import(
        /* webpackChunkName: "default-footer" */
        '@/components/dashboard/Footer'
      ),
      DefaultSettings: () => import(
        /* webpackChunkName: "default-settings" */
        '@/components/dashboard/Settings'
      ),
      DefaultView: () => import(
        /* webpackChunkName: "default-view" */
        '@/components/dashboard/View'
      ),
    },
    created () {
      this.$store.set('app/items', this.items)
      this.$store.set('app/viewcol', 12)

      if(this.$store.state.newauth.userProfile.is_superuser) {
        this.items.push({
          title: 'Users',
          icon: 'mdi-account-multiple',
          to: '/dashboard/users'
        },
        {
          title: 'Pages',
          icon: 'mdi-eye',
          to: '/dashboard/pages'
        },
        {
          title: 'Admin',
          icon: 'mdi-shield-crown',
          to: '/dashboard/admin'
        }
        )
      }
    }
  }
</script>

<style scoped>
/* #app {
  background: url('../assets/background_login.jpg') no-repeat center center  !important;
  background-size: cover;
} */
</style>